<template>
    <div class="card">
        <div class="card-header">
            <h3 class="card-title">Últimas acciones</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th style="width: 180px">Fecha</th>
                        <th>Expediente</th>
                        <th>Acción</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="acciones in ultimasacciones" :key="acciones.id_seguimiento">
                        <td>{{ acciones.fecha}}</td>
                        <td>{{ acciones.codigo_servicio}}</td>
                        <td>{{ acciones.texto_seguimiento }}</td>
                    </tr>

                </tbody>
            </table>
        </div>
        <!-- /.card-body -->
    </div>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
export default {
    props: [],
    data() {
        return {
           ultimasacciones:[],
        }
    },
    methods:
    {
        async cargardatos() {
            const api = new PwgsApi();
            this.ultimasacciones = await api.get('ultimas-acciones-usuario');
            
            
        }
    },
    mounted() {
        this.cargardatos();
    },
    watch:
    {
      
    }
}
</script>